<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card>
          <v-card-title>
            เบิกพนักงาน
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="3" lg="3">
                <v-date v-model="Widen.WidenEMPCreateDate"></v-date>
              </v-col>
              <v-col cols="12" sm="12" md="2" lg="2">
                <v-text-field
                  label="จำนวนเงิน"
                  placeholder="จำนวนเงิน"
                  disabled
                  dense
                  v-model="Widen.WidenTotal"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-text-field
                  label="รายละเอียด"
                  placeholder="รายละเอียด"
                  dense
                  v-model="Widen.WidenEMPRemark"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="1" lg="1">
                <v-btn color="primary" width="100%" @click="Insert()">
                  <v-icon>fa fa-save</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card>
          <v-card-title>
            บิลที่ค้างชำระ
          </v-card-title>
          <v-data-table
            :items-per-page="-1"
            :headers="headers"
            :items="desserts.Array"
            class="elevation-1"
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      Widen: {
        UserId: this.$route.query.UserId,
        WidenTotal: "",
        WidenEMPRemark: "",
        WidenEMPCreateDate: "",
      },
      UserId: this.$route.query.UserId,
      desserts: [],
      headers: [
        {
          text: "วันที่",
          align: "left",
          sortable: true,
          value: "Date",
        },
        {
          text: "จำนวนเงิน",
          align: "left",
          sortable: true,
          value: "Total",
          width: 100,
        },
        {
          text: "รับเงิน",
          align: "left",
          sortable: true,
          value: "Paid",
          width: 80,
        },
        {
          text: "คงเหลือ",
          align: "right",
          sortable: true,
          value: "Balance",
          width: 80,
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.Select(this.UserId);
    });
  },
  methods: {
    Insert() {
      this.Widen.Array = this.desserts.Array;
      axios
        .post("/api/v1/Insert/InsertWidenEMP/billsale", this.Widen)
        .then((res) => {
          if (res.status == 200) {
            this.Prints(res.data)
            this.Select(this.UserId);
          }
        });
    },
    Prints(v){
      axios.get(`/api/v1/FinanceWidenEMP/pdf?Id=${v.Id}`).then((res) => {
          if (res.status == 200) {
            this.PrintJs({
              printable: res.data.base64,
              type: "pdf",
              base64: true,
            });
          }
        });
    },
    Select(v) {
      axios
        .get("/api/v1/select/SelectByIdUserWiden/Wage?UserId=" + v)
        .then((res) => {
          if (res.status == 200) {
            this.desserts = res.data;
            this.Widen.WidenTotal = res.data.Sum;
          }
        });
    },
  },
};
</script>
